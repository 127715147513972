import {
  Center,
  Checkbox,
  CheckboxGroup,
  css,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useToolbox } from "../../data/toolboxApi";
import ToolboxItemCard from "../ToolboxItemCard";

type Props = {
  relatedItems: string[];
  onChange?: (items: string[]) => void;
};

const ToolboxGoalForm = ({ relatedItems, onChange }: Props) => {
  const { data, loading: isLoading, fetchData } = useToolbox();

  useEffect(() => {
    console.count("toolboxGoalFroms.tsx");
    fetchData();
  }, []);

  return isLoading ? (
    <Center>
      <Spinner p={4} />
    </Center>
  ) : data ? (
    <Stack>
      {data.map((item) => (
        <CheckboxGroup value={relatedItems} onChange={onChange}>
          <Checkbox
            w="full"
            value={item.id}
            css={{
              "& .chakra-checkbox__label": {
                width: "100%",
              },
            }}
          >
            <ToolboxItemCard item={item} compact />
          </Checkbox>
        </CheckboxGroup>
      ))}
    </Stack>
  ) : null;
};

export default ToolboxGoalForm;
