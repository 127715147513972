import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  Center,
  VStack,
  Icon,
  Alert,
  AlertIcon,
  Textarea,
  ButtonGroup,
  Link as ChakraLink,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaChevronLeft, FaEdit } from "react-icons/fa";
import GoalCard from "../../components/goals/GoalCard";
import GoalForm from "../../components/goals/GoalForm";
import Layout from "../../components/Layout";
import ToolboxItemCard from "../../components/ToolboxItemCard";
import { Goal, useDeleteGoal, useGoals } from "../../data/goalsApi";
import { useToolbox } from "../../data/toolboxApi";
import { AddIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import { FaTrash } from "react-icons/fa";
import SearchComponent from "../../components/SearchComponent";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { DeleteIcon } from "@chakra-ui/icons";
import Lottie from "react-lottie";
// import * as successCheck from "../../assets/52093-complete.json";
// import * as inProgress from "../../assets/goal-inprogress.json";
// import * as notStarted from "../../assets/in-progress-yellow.json";
import { ChevronDownIcon } from "@chakra-ui/icons";
import InstructionText from "../../components/InstructionText";

type Props = {};

const Goals = (props: Props) => {
  const { data: goals, refetch } = useGoals();
  const [editingGoal, setEditingGoal] = useState<Goal>();
  const [newGoal, setNewGoal] = useState<Goal>();
  const [selectedGoal, setSelectedGoal] = useState<Goal>();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [statusView, setStatusView] = useState("ALL");
  const { onOpen: onOpenCreateGoal, isOpen: isOpenCreateGoal, onClose: onCloseCreateGoal } = useDisclosure();
  const { onOpen: onOpenEditGoal, isOpen: isOpenEditGoal, onClose: onCloseEditGoal } = useDisclosure();
  const { data: toolbox } = useToolbox();
  const deleteGoal = useDeleteGoal();
  var animationData;

  // const statusAnimation = useMemo(() => {
  //   if (selectedGoal?.status === "STARTED") {
  //     animationData = inProgress;
  //     return inProgress;
  //   }

  //   if (selectedGoal?.status === "COMPLETE") {
  //     animationData = successCheck;
  //     return successCheck;
  //   }

  //   if (selectedGoal?.status === "NOT_STARTED") {
  //     animationData = notStarted;
  //     return animationData;
  //   }
  // }, [selectedGoal]);

  const statusColour = useMemo(() => {
    switch (selectedGoal?.status) {
      case "STARTED":
        return "orange";
      case "COMPLETE":
        return "green";
      default:
        return "purple";
    }
  }, [selectedGoal]);

  const statusText = useMemo(() => {
    switch (selectedGoal?.status) {
      case "STARTED":
        return "In Progress";
      case "COMPLETE":
        return "Complete";
      default:
        return "Not Started";
    }
  }, [selectedGoal]);

  const filteredGoals = useMemo(() => {
    return goals?.filter((goal) => {
      if (statusView === "ALL") return true;
      return goal.status === statusView;
    });
  }, [goals, statusView]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initId = params.get("initId");
    if (initId && goals) {
      const goal = goals.find((g) => g.itemId === initId);
      if (goal) {
        setSelectedGoal(goal);
        const url = new URL(location.href);
        url.searchParams.delete("initId");
        history.replaceState(null, "", url.toString());
      }
    }
  }, [goals]);

  const bg = useColorModeValue("white", "gray.800");
  const gray = useColorModeValue("gray.100", "gray.800");
  const textColor = useColorModeValue("blue.800", "white");

  const deleteDialog = useDisclosure();
  const cancelRef = useRef<any>();

  useEffect(() => {
    document.title = "Goals";
  }, []);

  return (
    <main aria-label="Goals">
      <Layout>
        <Flex p={isMobile ? 3 : 0}>
          <Heading as="h1" mb={3}>
            Goals
          </Heading>
          <Spacer />
          <InstructionText
            title={"About Goals"}
            mdId={"instruction-text/goals"}
          />
          {
            isMobile ? <SearchComponent asInput={false} dark={false} /> : null
            // <AddLink refetch={refetch} />
          }
        </Flex>
        <Flex w="full" flex={1} gap={10}>
          {(isMobile && !selectedGoal) || !isMobile ? (
            <Box
              minW={0}
              flex={isMobile ? 1 : 0.4}
              rounded="lg"
              bg={bg}
              boxShadow="lg"
              p={8}
            >
              <Flex>
                <Select
                  value={statusView}
                  onChange={(e) => {
                    setStatusView(e.target.value);
                  }}
                  mb={"20px"}
                  aria-label="Filter goals"
                >
                  <option value="ALL">Everything</option>
                  <option value="STARTED">In Progress</option>
                  <option value="COMPLETE">Complete</option>
                </Select>
                <ButtonGroup
                  ml={2}
                  isAttached
                  variant="outline"
                  onClick={onOpenCreateGoal}
                >
                  <IconButton aria-label="Add new goal" icon={<AddIcon />} />
                </ButtonGroup>
              </Flex>

              <Flex flexDir="column" gap={2}>
                {filteredGoals && filteredGoals.length > 0 ? (
                  filteredGoals.map((goal) => (
                    <GoalCard
                      goal={goal}
                      onClick={() => setSelectedGoal(goal)}
                    />
                  ))
                ) : (
                  <>
                    <ChakraLink
                      style={{ textDecoration: "none" }}
                      onClick={onOpenCreateGoal}
                    >
                      <Box
                        border="1.5px"
                        borderColor={textColor}
                        w="100%"
                        h={"220px"}
                        p={"80px"}
                        mb={4}
                        mt={4}
                        color="white"
                        rounded={"xl"}
                      >
                        <Center>
                          <VStack>
                            <Icon
                              color={textColor}
                              as={AddIcon}
                              w={7}
                              h={7}
                              mb="10px"
                            />
                            <Button
                              color={textColor}
                              variant={"link"}
                              style={{ textDecoration: "none" }}
                              fontSize="md"
                              fontWeight="bold"
                            >
                              Add a new goal.
                            </Button>
                          </VStack>
                        </Center>
                      </Box>
                    </ChakraLink>

                    <>
                      <Alert rounded={"xl"}>
                        <Text size="sm">
                          It looks like you don't have any goals yet.{" "}
                          {statusView !== "ALL" &&
                            'Try changing the filter to "Everything" to see all your goals.'}{" "}
                        </Text>
                      </Alert>
                    </>
                  </>
                )}
              </Flex>
            </Box>
          ) : null}
          {(isMobile && selectedGoal) || !isMobile ? (
            <Box
              flex={1}
              maxW="100%"
              minW={0}
              rounded="lg"
              bg={bg}
              boxShadow="lg"
            >
              {selectedGoal ? (
                <>
                  <Box
                    p={8}
                    w="100%"
                    h="auto"
                    bg={bg}
                    borderTopRadius={"lg"}
                  >
                    <Flex minWidth="max-content" alignItems="center" gap="2">
                      <IconButton
                        onClick={() => setSelectedGoal(undefined)}
                        aria-label="back"
                        icon={<FaChevronLeft />}
                        color="white"
                        bgColor="blue.500"
                      />

                      <Spacer />

                      <IconButton
                        aria-label="back"
                        icon={<FaTrash />}
                        color="white"
                        bgColor="blue.500"
                        onClick={deleteDialog.onOpen}
                        isLoading={deleteGoal.isLoading}
                      />
                      <Button
                        aria-label="Edit"
                        colorScheme={"blue"}
                        onClick={() => {
                          setEditingGoal(selectedGoal);
                          onOpenEditGoal();
                        }}
                      >
                        Edit Goal & Status
                      </Button>
                    </Flex>
                    <Flex mt={3}>
                      {/* <Box
                        mt={4}
                        shadow={"xl"}
                        rounded="full"
                        bgColor={"white"}
                        minH={"80px"}
                        maxH={"80px"}
                        minW={"80px"}
                        maxW={"80px"}
                        mr={5}
                      >
                        <Lottie
                          options={{
                            loop: false,
                            autoplay: true,
                            animationData,
                          }}
                          height={80}
                          width={80}
                        ></Lottie>
                      </Box> */}
                      <div>
                        <Heading
                          mt={6}
                          as="h2"
                          color={textColor}
                          wordBreak={"break-word"}
                        >
                          {selectedGoal.title}
                        </Heading>
                        <Tag colorScheme={statusColour} mt={2}>
                          {statusText}
                        </Tag>

                        {/* <Menu>
                          <MenuButton
                            size="xs"
                            bg={"green.300"}
                            color={"white"}
                            variant="solid"
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            mt={1}
                          >
                            {statusText}
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => alert("not started selected")}
                              value="not started"
                            >
                              Not started
                            </MenuItem>
                            <MenuItem
                              onClick={() => alert("in progress selected")}
                              value=""
                            >
                              In Progress
                            </MenuItem>
                            <MenuItem
                              onClick={() => alert("complete selected")}
                              value=""
                            >
                              Complete
                            </MenuItem>
                          </MenuList>
                        </Menu> */}
                      </div>
                    </Flex>
                  </Box>
                  <Box p={8}>
                    <Text
                      placeholder="Add a description"
                      size="sm"
                      border={"none"}
                    >
                      {selectedGoal.description}
                    </Text>
                  </Box>
                </>
              ) : (
                <Flex minW="0" h="full" justify="center" alignItems="center">
                  <Text opacity={0.8} maxW={400} textAlign="center">
                    Select a goal to view it here
                  </Text>
                </Flex>
              )}
            </Box>
          ) : null}
        </Flex>
      </Layout>

      <Modal isOpen={isOpenCreateGoal} onClose={onCloseCreateGoal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Create Goal</ModalHeader>
          <ModalBody>
            <GoalForm
              editing={newGoal}
              onComplete={() => {
                onCloseCreateGoal();
                refetch();
              }}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenEditGoal} onClose={onCloseEditGoal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Create Goal</ModalHeader>
          <ModalBody>
            <GoalForm
              editing={editingGoal}
              onComplete={() => {
                onCloseEditGoal();
                refetch();
              }}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>





      <AlertDialog
        isOpen={deleteDialog.isOpen}
        onClose={deleteDialog.onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Goal
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={deleteDialog.onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  await deleteGoal.mutateAsync(selectedGoal?.itemId);
                  await refetch();
                  setSelectedGoal(undefined);
                  deleteDialog.onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </main>
  );
};

export default Goals;
