import {
  Button,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Stack,
  Radio,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  CreateGoalDTO,
  Goal,
  GoalStatus,
  useCreateGoal,
  useUpdateGoal,
} from "../../data/goalsApi";
import CompleteNotification from "../CompleteNotification";
import ToolboxGoalForm from "./ToolboxGoalForm";

type Props = {
  editing?: Goal;
  onComplete?: () => void;
};

const GoalForm = ({ editing, onComplete }: Props) => {
  const [goal, setGoal] = useState<CreateGoalDTO>({
    title: "",
    description: "",
    status: "NOT_STARTED",
    relatedItems: [],
  });
  const create = useCreateGoal();
  const update = useUpdateGoal();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: showCompleteNotification,
    isOpen: completeNotificationIsOpen,
    onClose: closeCompleteNotification,
  } = useDisclosure();

  useEffect(() => {
    if (editing) {
      setGoal(editing);
    }
  }, [editing]);

  const closeCompleteNotificationAndComplete = () => {
    closeCompleteNotification();
    onComplete?.();
  };

  const handleSubmit = async () => {
    if (editing) {
      await update.mutateAsync(editing.id, goal);
      if (editing.status !== "COMPLETE" && goal.status === "COMPLETE")
        showCompleteNotification();
    } else {
      await create.mutateAsync(goal);
      if (goal.status === "COMPLETE") showCompleteNotification();
    }
    if (!(goal.status === "COMPLETE")) onComplete?.();
  };

  return (
    <>
      <Stack spacing={5}>
        <FormControl>
          <FormLabel>Goal Title</FormLabel>
          <Input
            value={goal.title}
            onChange={(e) =>
              setGoal((prev) => ({ ...prev, title: e.target.value }))
            }
            placeholder="Enter your goal title here"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Goal Description</FormLabel>
          <Textarea
            value={goal.description}
            onChange={(e) =>
              setGoal((prev) => ({ ...prev, description: e.target.value }))
            }
            placeholder="Enter your goal description here"
          />
        </FormControl>

        <FormControl>
          <FormLabel>Set Goal Status</FormLabel>
          <RadioGroup
            value={goal.status}
            onChange={(value: GoalStatus) =>
              setGoal((prev) => ({ ...prev, status: value }))
            }
          >
            <Stack spacing={5} ml={3}>
              <Radio value="NOT_STARTED">Not Started</Radio>
              <Radio value="STARTED">In Progress</Radio>
              <Radio value="COMPLETE">Complete</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <Button
          colorScheme="blue"
          onClick={handleSubmit}
          isLoading={create.isLoading || update.isLoading}
        >
          Save Changes
        </Button>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Toolbox</ModalHeader>
          <ModalBody>
            <ToolboxGoalForm
              relatedItems={goal.relatedItems}
              onChange={(items) =>
                setGoal((prev) => ({ ...prev, relatedItems: items }))
              }
            />
            <Button mt={5} colorScheme="blue" w="full" onClick={onClose}>
              Save
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <CompleteNotification
        title="Well Done!"
        subtitle="You have completed your goal."
        isOpen={completeNotificationIsOpen}
        onClose={closeCompleteNotificationAndComplete}
      />
    </>
  );
};

export default GoalForm;
